import React from 'react'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '3rem 8rem',
    boxShadow: `0px 4px 21px ${theme.card.shadow}`,
    marginTop: '2.4rem',
  },
  title: {
    marginBottom: '1.6rem',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const Label = styled.span`
  font-weight: 500;
`

const DriverStatus = ({
  driver: {driverName = '', licensePlate = '', driverPhone = ''} = {},
  driver,
}) => {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Typography variant="h1" className={classes.title}>
        Driver Details
      </Typography>
      {driver && driver.driverName ? (
        <div className={classes.container}>
          <Typography variant="subtitle1">
            <Label> Driver: </Label>
            {driverName}
          </Typography>
          <Typography variant="subtitle1">
            <Label>Vehicle: </Label>
            {licensePlate || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <Label>Phone: </Label>
            {driverPhone}
          </Typography>
        </div>
      ) : (
        <Typography variant="subtitle1">
          Driver has not been assigned yet.
        </Typography>
      )}
    </Paper>
  )
}

export default DriverStatus
