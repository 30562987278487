import {SHOW_ERROR, SILENCE_ERROR, START_LOADING, STOP_LOADING} from './types'

const showError = (message) => {
  return {
    type: SHOW_ERROR,
    payload: message,
  }
}
const silenceError = () => {
  return {
    type: SILENCE_ERROR,
  }
}

const startLoading = () => {
  return {
    type: START_LOADING,
  }
}
const stopLoading = () => {
  return {
    type: STOP_LOADING,
  }
}

export {showError, silenceError, startLoading, stopLoading}
