import styled, {keyframes, css} from 'styled-components'
import {PrimaryButton} from '../Button'

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export const Canvas = styled.canvas`
  visibility: hidden;
`

export const Video = styled.video`
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`

export const Overlay = styled.div`
  ${({offsetX, offsetY}) => `
  position: absolute;
  top: ${offsetY / 10}rem;
  right: ${offsetX / 10}rem;
  bottom: ${offsetY / 10}rem;
  left: ${offsetX / 10}rem;
  box-shadow: 0px 0px 12rem 12rem rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  `}
`

export const CameraButton = styled(PrimaryButton)`
  left: 50%;
  transform: translatex(-50%);
`

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;

  ${({flash}) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `
    }
  }}
`
