import React from 'react'
import styled from 'styled-components'
import {makeStyles} from '@material-ui/core/styles'
import get from 'lodash.get'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Paper from '@material-ui/core/Paper'

import StepConnector from '@material-ui/core/StepConnector'
import Typography from '@material-ui/core/Typography'

import {generalFormatDate, formatDate} from '../../utils/dates'
import {getStatusCopy} from '../../utils/common'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '3rem 8rem',
    boxShadow: `0px 4px 21px ${theme.card.shadow}`,
    marginTop: '2.4rem',
  },
  stepperRoot: {
    height: 'auto',
  },
  step: {
    borderRadius: '50%',
    backgroundColor: 'white',
  },
  line: {
    borderTopWidth: 4,
    borderColor: 'inherit',
  },
  connector: {
    borderColor: theme.colors.stroke,
    transform: 'translateY(-100%)',
    width: '100%',
    left: 'calc(-50% + 10px)',
  },
  connectorActive: {
    borderColor: theme.colors.primary,
  },
}))

function getSteps() {
  return [
    'Ready for Pickup',
    'Pickup in Progress',
    'Delivery in Progress',
    'Delivery Complete',
  ]
}

const StepIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid ${({theme}) => theme.colors.stroke};
  background-color: white;
  position: relative;

  .MuiStep-completed & {
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: ${({theme}) => theme.colors.primary};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    border: 2px solid ${({theme}) => theme.colors.primary};
  }
`

const Label = styled.span`
  font-weight: 500;
`
const StepperContainer = styled.div`
  margin: 1.5rem auto 2.8rem auto;
`

export default function DeliveryStatusTracker({
  delivery: {storeName, deliveryDate, deliveryStatus} = {},
}) {
  const classes = useStyles()
  const steps = getSteps()
  const currentStatus = getStatusCopy(deliveryStatus)
  const statusLabel = get(currentStatus, 'label', '')
  const step = get(currentStatus, 'step', 1)
  return (
    <Paper className={classes.root}>
      <Typography variant="subtitle1">
        <Label>Status: </Label>
        {statusLabel}
      </Typography>
      <StepperContainer>
        <Stepper
          className={classes.stepperRoot}
          alternativeLabel
          activeStep={step}
          connector={
            <StepConnector
              classes={{
                root: classes.connector,
                line: classes.line,
                completed: classes.connectorActive,
              }}
            />
          }>
          {steps.map((label) => (
            <Step key={label} className={classes.step}>
              <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperContainer>
      <Typography variant="subtitle1">
        <Label> Delivery Date: </Label>
        {formatDate(generalFormatDate(deliveryDate))}
      </Typography>
      <Typography variant="subtitle1">
        <Label>Pharmacy Location: </Label>
        {storeName}
      </Typography>
    </Paper>
  )
}
