import React from 'react'

import ConfirmationDialog from '../ConfirmationDialog'

const DeleteOrder = ({primaryAction, secondaryAction}) => {
  const description =
    'This order will be deleted and it will be removed from this delivery.'

  return (
    <ConfirmationDialog
      closeModal={secondaryAction}
      handleContinueAction={primaryAction}
      handleCancelAction={secondaryAction}
      description={description}
      cancelActionLabel="Cancel"
      continueActionLabel="Delete Order"
    />
  )
}

export default DeleteOrder
