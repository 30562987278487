import React, {useState, useRef, useEffect} from 'react'
import Measure from 'react-measure'
import {makeStyles} from '@material-ui/core/styles'

import {useUserMedia} from './hooks/use-user-media'
import {
  Video,
  Canvas,
  Wrapper,
  Container,
  CameraButton,
  Flash,
  Overlay,
} from './styles'

const CAPTURE_OPTIONS = {
  audio: false,
  video: {
    facingMode: 'environment',
    width: window.innerWidth,
    height: window.innerHeight,
  },
}

// Limit the dimensions of the image we upload to keep the file size from being too big
const IMAGE_UPLOAD_DIMENSIONS = {
  width: 588,
  height: 336,
}

const useStyles = makeStyles((theme) => ({
  cameraBtn: {
    position: 'absolute',
    bottom: '4rem',
    width: '75%',
  },
}))

export function Camera({
  style,
  onRequestDeviceCamera,
  onAcquireDeviceCamera,
  onCapture,
  onClear,
}) {
  const canvasRef = useRef()
  const videoRef = useRef()
  const classes = useStyles()
  const {innerWidth: windowWidth, innerHeight: windowHeight} = window

  const [container, setContainer] = useState({
    width: windowWidth,
    height: windowHeight,
  })

  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true)
  const [isFlashing, setIsFlashing] = useState(false)

  const mediaStream = useUserMedia(CAPTURE_OPTIONS)
  useEffect(() => {
    onRequestDeviceCamera()
  }, [onRequestDeviceCamera])

  // Padding percentage is used to calculate position of the Overlay components
  // in relation to the window size, for a full screen camera view
  // It is also used to draw positional offsets of the Video component if Video dimensions are larger
  // than full screen container dimensions
  const PADDING_X_PERCENTAGE = 0.3
  const PADDING_Y_PERCENTAGE = 0.35

  const OVERLAY_OFFSETX = container.width * PADDING_X_PERCENTAGE
  const OVERLAY_OFFSETY = container.height * PADDING_Y_PERCENTAGE

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream
  }

  function handleCanPlay() {
    onAcquireDeviceCamera()
    setIsVideoPlaying(true)
    videoRef.current.play()
  }

  function handleCapture() {
    const context = canvasRef.current.getContext('2d')
    const video = videoRef.current
    const videoRect = video.getBoundingClientRect()

    // Video source is scaled to fill the page
    const [mediaSteamTrack] = video.srcObject.getVideoTracks()
    const mediaStreamSettings = mediaSteamTrack.getSettings()
    const videoScale = {
      width: mediaStreamSettings.width / videoRect.width,
      height: mediaStreamSettings.height / videoRect.height,
    }

    const overlayWidth = container.width - OVERLAY_OFFSETX * 2
    const overlayHeight = container.height - OVERLAY_OFFSETY * 2

    // Overlay relative to video element, scaled to video source
    const startX = videoScale.width * (videoRect.width - overlayWidth) / 2
    const startY = videoScale.height * (videoRect.height - overlayHeight) / 2
    const sourceWidth = videoScale.width * overlayWidth
    const sourceHeight = videoScale.height * overlayHeight
  
    context.drawImage(
      video,
      startX,
      startY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      IMAGE_UPLOAD_DIMENSIONS.width,
      IMAGE_UPLOAD_DIMENSIONS.height,
    )

    canvasRef.current.toBlob((blob) => onCapture(blob), 'image/png')
    setIsCanvasEmpty(false)
    setIsFlashing(true)
  }

  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: contentRect.bounds.height,
    })
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({measureRef}) => (
        <Wrapper>
          <Container style={style} ref={measureRef}>
            <Video ref={videoRef} onCanPlay={handleCanPlay} autoPlay muted />

            <Overlay
              offsetX={OVERLAY_OFFSETX}
              offsetY={OVERLAY_OFFSETY}
              hidden={!isVideoPlaying}
            />

            <Canvas
              ref={canvasRef}
              width={IMAGE_UPLOAD_DIMENSIONS.width}
              height={IMAGE_UPLOAD_DIMENSIONS.height}
            />

            <Flash
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />

            {isVideoPlaying && isCanvasEmpty && (
              <CameraButton
                id="capture-label-button"
                className={classes.cameraBtn}
                onClick={handleCapture}>
                Capture Label
              </CameraButton>
            )}
          </Container>
        </Wrapper>
      )}
    </Measure>
  )
}
