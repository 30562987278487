import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiRadio from '@material-ui/core/Radio'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.colors.stroke,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

function StyledRadio(props) {
  const classes = useStyles()

  return (
    <MuiRadio
      className={classes.root}
      disableRipple
      color="primary"
      {...props}
    />
  )
}

const Radio = ({id, ...rest}) => (
  <FormControlLabel
    value="cash"
    control={<StyledRadio id={id} />}
    label="Cash"
    {...rest}
  />
)

export default Radio
