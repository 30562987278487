import React, { useState } from 'react'
import styled from 'styled-components'

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SortButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font: inherit;

  &:focus {
    outline: none;
  }
`

const SortIcon = styled.div`
  margin-left: 1rem;
  display: inline-flex;
  flex-direction: column;

  svg {
    font-size: 2rem;
    margin-top: -6px;
    margin-bottom: -6px;
  }

  svg:nth-child(1) {
    color: ${({theme, sortDesc}) => sortDesc ? theme.colors.iconfill : theme.colors.primary3 };
  }

  svg:nth-child(2) {
    color: ${({theme, sortAsc}) => sortAsc ? theme.colors.iconfill : theme.colors.primary3 };
  }
`

const SortableTableHeadCell = ({ header, children }) => {
  const [sort, setSort] = useState({ asc: false, desc: false })

  const { property } = header

  const onClick = () => {
    const isSorted = sort.asc || sort.desc

    const newSort = isSorted
      ? { asc: !sort.asc, desc: !sort.desc }
      : { asc: true, desc: false }

    setSort(newSort)

    const direction = newSort.asc ? 'asc' : 'desc'
    header.onClick(property, direction)
  }

  return (
    <SortButton type="button" onClick={onClick}>
      {children}
      <SortIcon sortAsc={sort.asc} sortDesc={sort.desc}>
        <ExpandLessIcon />
        <ExpandMoreIcon />
      </SortIcon>
    </SortButton>
  )
}

const TableHeadCell = ({ header = {}, children }) => {
  const { onClick } = header
  const isSortableColumn = !!onClick

  return isSortableColumn
    ? (<SortableTableHeadCell header={header}>{children}</SortableTableHeadCell>)
    : children
}

export default TableHeadCell;
