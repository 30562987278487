import {
  GET_ORDER,
  GET_ORDER_SUCCESS,
  SEARCH_ORDERS,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_ORDERS_CLEAR,
  CLEAR_ORDERS,
  NO_MORE_ORDERS,
} from '../actions/types'

function ordersDataReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_ORDER: {
      return {...state, order: action.payload}
    }
    case GET_ORDER_SUCCESS: {
      return {...state, order: action.payload}
    }
    case SEARCH_ORDERS: {
      return {...state}
    }
    case SEARCH_ORDERS_SUCCESS: {
      return {...state, orders: [...state.orders, ...action.payload]}
    }
    case SEARCH_ORDERS_CLEAR: {
      return {...state}
    }
    case CLEAR_ORDERS: {
      return {...state, orders: [], hasMoreOrders: true}
    }
    case NO_MORE_ORDERS: {
      return {...state, hasMoreOrders: false}
    }
    default:
      return state
  }
}

export default ordersDataReducer
