import React, {useEffect} from 'react'
import styled from 'styled-components'
import {toast} from 'react-toastify'
import {connect} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import get from 'lodash.get'

import {API_ERRORS_MAPPER} from '../../utils/constants'
import {silenceError} from '../../redux/actions'

import 'react-toastify/dist/ReactToastify.css'

const ToastMessage = styled(ToastContainer).attrs({})`
  .Toastify__close-button > svg {
    fill: ${(props) => props.theme.colors.lightText};
  }
  .Toastify__close-button--default {
    opacity: 1;
  }
  .Toastify__progress-bar {
    background: ${(props) => props.theme.colors.errorDark};
  }
  .Toastify__toast--default {
    background: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.lightText};
  }
  .Toastify__toast-body {
    background: ${(props) => props.theme.colors.error};
    font-family: ${(props) => props.theme.fonts.primary};
    font-size: 14px;
  }
`

const Toast = ({error, silenceError}) => {
  useEffect(() => {
    const code = get(error, 'code')
    const action = get(API_ERRORS_MAPPER[code], 'action', () => {})

    !!error && error.message
      ? toast(error.message, {
          toastId: 'error-toast-message',
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: action,
        })
      : action()
    silenceError()
  }, [error, silenceError])
  return <ToastMessage id="error-toast-message" />
}

const mapStateToProps = ({app}) => ({error: app.error})

const mapDispatchToProps = (dispatch) => ({
  silenceError: () => dispatch(silenceError()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Toast)
