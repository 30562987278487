import {SCAN_LABEL} from './types'

const scanLabels = (image, history) => {
  return {
    type: SCAN_LABEL,
    payload: {image, history},
  }
}

export {scanLabels}
