import React, {useCallback, useRef, useState, useEffect} from 'react'
import styled from 'styled-components'

import {DialogTitle} from '@material-ui/core'
import {connect} from 'react-redux'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from '@material-ui/core'
import theme from './../../theme'
import TableHeadCell from '../Table/TableHeadCell'
import {OrderDetailList} from '../OrderDetailList'
import {getPaymentMessage} from '../Table'
import {formatDate, generalFormatDate} from '../../utils/dates'
import {searchOrders} from '../../redux/actions'
import StatusInfo from '../StatusInfo'
import Modal from './Modal'
import OrderDetailModal from './OrderDetailModal'
import {MoreIcon} from '../Icons'
import CheckBox from '../Checkbox'

export const TABLE_HEADERS = [
  {label: 'Delivery Date'},
  {label: 'Name'},
  {label: 'Address', width: '25%'},
  {label: 'Phone'},
  {label: 'TX Number / Price'},
  {label: 'Customer Payment'},
  {label: 'Status'},
  {label: 'More'},
]

export const TABLE_PROPERTIES = [
  'deliveryDate',
  'customerName',
  'customerAddress',
  'customerPhone',
  'detail',
  'paymentType',
  'orderStatus',
  'orderId',
]

const DialogContainer = styled.div`
  padding: 2.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 85vw;
`

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 85%;
  margin-top: 1.3rem;
`

const DialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
`

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.colors.background,
    color: theme.colors.label,
    border: 'none',
    height: '3rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  body: {
    backgroundColor: theme.colors.secondaryBackground,
    fontSize: 13,
    padding: '1rem 1.3rem',
    verticalAlign: 'middle',
    '&:not(first-child)': {
      borderWidth: '1px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:first-child': {
      borderWidth: '1px 0 1px 1px',
      borderRadius: '5px 0 0 5px',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
    '&:last-child': {
      borderWidth: '1px 1px 1px 0',
      borderRadius: '0 5px 5px 0',
      borderColor: theme.colors.stroke,
      borderStyle: 'solid',
    },
  },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: '17.1rem',
    marginBottom: 0,
  },
  dialogTitle: {
    fontFamily: 'Roboto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: '3rem',
    padding: 0,
    color: theme.colors.textPrimary,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    height: '85%',
  },
  tableContainer: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    marginTop: '0.8rem',
  },
  tableHead: {
    color: theme.colors.label,
    border: 'none',
    width: '100%',
  },
  row: {
    margin: '0 2rem',
    padding: '0rem',
    verticalAlign: 'top',
  },
  checkbox: {
    '.PrivateSwitchBase-input-61': {
      position: 'relative !important',
    },
  },
}))

const OrdersTableHead = ({header}) => {
  const style = header.width ? {width: header.width} : {}
  return (
    <StyledTableCell align="left" style={{...style, paddingLeft: '1.3rem'}}>
      <TableHeadCell header={header}>{header.label}</TableHeadCell>
    </StyledTableCell>
  )
}

// Utility to debounce the IntersectionObserver callback
function debounce(func, delay) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const SearchedOrdersModalContent = ({
  handleClose,
  orders = {},
  loading,
  searchOrders,
  searchParams,
  setSearchParams,
  hasMoreOrders = true,
}) => {
  const classes = useStyles()
  const observer = useRef()
  const tableRef = useRef(null)
  const [localOrders, setLocalOrders] = useState(orders)
  const [orderIdSelected, setOrderIdSelected] = useState()
  const [modal, setModal] = React.useState({
    isOpen: false,
  })
  const [isNarcotics, setIsNarcotics] = useState(false)

  useEffect(() => {
    setLocalOrders((prevOrders) => [
      ...prevOrders,
      ...orders.slice(prevOrders.length),
    ])
  }, [orders])

  useEffect(() => {
    if (isNarcotics) {
      setLocalOrders(
        orders.filter((order) => order.detail.some((d) => d.isNarcotics)),
      )
    } else {
      setLocalOrders(orders)
    }
  }, [isNarcotics])

  useEffect(() => {
    console.log('🚀 ~ useEffect ~ observer:', observer)
    if (observer.current && localOrders.length > 0) {
      const lastOrderElement = document.querySelector(
        `[data-index="${localOrders.length - 1}"]`,
      )
      console.log('🚀 ~ useEffect ~ lastOrderElement:', lastOrderElement)
      if (lastOrderElement) {
        observer.current.observe(lastOrderElement) // Attach ref to the last element
      }
    }

    return () => {
      if (observer.current) observer.current.disconnect() // Clean up observer
    }
  }, [localOrders])

  const lastOrderElementRef = useCallback(
    (node) => {
      if (loading || !hasMoreOrders) return

      if (observer.current) {
        observer.current.disconnect()
        return
      } // Ensure disconnection to avoid multiple triggers

      observer.current = new IntersectionObserver(
        debounce((entries) => {
          // Only fetch more orders if the last element is intersecting and there are more orders to fetch
          if (entries[0].isIntersecting && hasMoreOrders) {
            // Disconnect the observer once the fetch starts to prevent multiple triggers
            observer.current.disconnect()

            setSearchParams((prevParams) => ({
              ...prevParams,
              offset: prevParams.offset + prevParams.limit,
            }))

            searchOrders({
              searchInput: searchParams.searchInput,
              limit: searchParams.limit,
              offset: searchParams.offset + searchParams.limit,
            })
          }
        }, 300),
        {
          rootMargin: '100px', // Adjust rootMargin to add buffer space
        },
      )

      if (node) observer.current.observe(node)
    },
    [loading, hasMoreOrders, searchParams, setSearchParams, searchOrders],
  )

  function composeTableHeaders(header, index) {
    return <OrdersTableHead key={index} header={header} />
  }

  function composeTableCells(row) {
    const borderColor = theme.colors.stroke
    return (key, index) => {
      switch (key) {
        case 'detail':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              <OrderDetailList detail={row[key]} />
            </StyledTableCell>
          )
        case 'paymentType':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              <>
                {getPaymentMessage(row, key)}
                <span>
                  {row[key] === 'SR_CARD' && row.paymentTotal !== null
                    ? `$${row.paymentTotal} (ScriptRunner Card)`
                    : '$0.00'}
                </span>
              </>
            </StyledTableCell>
          )
        case 'orderStatus':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              <StatusInfo order={row} />
            </StyledTableCell>
          )
        case 'deliveryDate':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              {formatDate(generalFormatDate(row[key]))}
            </StyledTableCell>
          )
        case 'orderId':
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}
              isRefrigerated={hasRefrigeratedItems(row)}
              onClick={(e) => handleMoreIconClick(row[key])}>
              <MoreIcon />
            </StyledTableCell>
          )
        default:
          return (
            <StyledTableCell
              key={`prop-${index}-${key}`}
              align="left"
              style={{borderColor}}>
              {row[key]}
            </StyledTableCell>
          )
      }
    }
  }

  function composeTableRows(row, index) {
    return (
      <TableRow
        ref={index === localOrders.length - 1 ? lastOrderElementRef : null}
        key={index}
        className={classes.row}
        data-index={index} // Add a data attribute to help identify this element
      >
        {TABLE_PROPERTIES.map(composeTableCells(row))}
      </TableRow>
    )
  }

  const hasRefrigeratedItems = (row) => {
    return row.detail?.some((item) => item.isRefrigerated)
  }

  const setOrderDetailModal = () => {
    setModal({
      isOpen: true,
    })
  }

  function handleMoreIconClick(orderId) {
    setOrderIdSelected(orderId)
    setOrderDetailModal()
  }

  const handleModalClose = () => {
    setModal({
      isOpen: false,
    })
  }

  const onNarcoticsChange = () => {
    setIsNarcotics(!isNarcotics)
  }

  return (
    <DialogContainer
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
        maxHeight: '85vh',
      }}>
      {loading && localOrders.length === 0 ? (
        ''
      ) : (
        <>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <DialogHeader>Search Results</DialogHeader>
            <div style={{flexGrow: '1', marginLeft: '4rem'}}>
              <CheckBox
                onChange={(e) => onNarcoticsChange()}
                defaultChecked={isNarcotics}
                label="Narcotic / CS"
                value={isNarcotics}
                style={{height: '20px'}}
              />
            </div>
            <CloseIcon
              color="primary"
              onClick={handleClose}
              style={{cursor: 'pointer'}}
            />
          </DialogTitle>
          <DialogBody>
            <TableContainer className={classes.tableContainer} ref={tableRef}>
              <Table
                id="table"
                stickyHeader
                className={classes.table}
                aria-label="customized table"
                moreIconClick={handleMoreIconClick}>
                <TableHead className={classes.tableHead}>
                  <TableRow>{TABLE_HEADERS.map(composeTableHeaders)}</TableRow>
                </TableHead>
                <TableBody>
                  {!!localOrders.length && localOrders.map(composeTableRows)}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogBody>
          <Modal isOpen={modal.isOpen} handleClose={handleModalClose}>
            <OrderDetailModal
              handleClose={handleModalClose}
              orderId={orderIdSelected}
              isOpen={modal.isOpen}
              loading
              showLoadingSpinner={false}
            />
          </Modal>
        </>
      )}
    </DialogContainer>
  )
}

const mapStateToProps = ({app = {}, orders = {}}) => {
  return {
    orders: orders.orders,
    hasMoreOrders: orders.hasMoreOrders,
  }
}
const mapDispatchToProps = {
  searchOrders,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchedOrdersModalContent)
