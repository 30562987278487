import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
  },
}))

const CustomIconButton = ({onClick, children, ...rest}) => {
  const classes = useStyles()

  return (
    <IconButton {...rest} className={classes.iconButton} onClick={onClick}>
      {children}
    </IconButton>
  )
}

export {CustomIconButton}
