import React, {useCallback, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {connect} from 'react-redux'

import Typography from '@material-ui/core/Typography'

import {
  getDeliveries,
  searchOrders,
  addDelivery,
  getStoreInfo,
  updateSearchTerm,
  // updateNarcoticsFilter,
  setOrders,
  clearSearchOrders,
  getAllCards,
  clearDeliveries,
} from '../../redux/actions'

import NewDeliverySection from '../../components/NewDeliverySection'
import Loading from '../../components/LoadingOrange'
import useDeliveryDateFilter, {
  DeliveryFilterOption,
} from '../../hooks/useDeliveryDateFilter'
import DeliveryTable from '../../components/DeliveryTable'
import DeliveryFilter from '../DeliveryFilter'
import {Modal, SearchedOrdersModal} from '../../components/Modal'
import {DELIVERIES_FETCH_LIMIT, ORDERS_FETCH_LIMIT} from '../../utils/constants'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background};
  padding: 2.8rem 4.7rem;
`

const HeaderContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin: 1.6rem 0 1.2rem 0;
`

const DEBOUNCE_DELAY = 600

// Utility to debounce the IntersectionObserver callback
function debounce(func, delay) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(...args)
    }, delay)
  }
}
const Home = ({
  getDeliveries,
  getFilteredDeliveries,
  getSearchedOrders,
  clearSearchedOrders,
  getStoreInfo,
  newDelivery,
  searchTerm,
  updateSearchTerm,
  deliveries = [],
  dateFilter,
  user = {},
  token = '',
  loading,
  activeTimeline,
  // isNarcotics = false,
  // updateNarcoticsFilter,
  hasMoreDeliveries = true,
  getAllCards,
  clearDeliveries,
}) => {
  const history = useHistory()
  const observer = useRef()
  const {
    filterOptions,
    onChangeSearchInput,
    searchInput,
    setSearchInput,
    visibleDeliveries,
    currentDeliveries,
    showCurrentDeliveries,
  } = useDeliveryDateFilter(
    deliveries,
    searchTerm,
    activeTimeline /* , isNarcotics */,
  )

  const [modal, setModal] = React.useState({
    isOpen: false,
    modalContent: '',
  })
  const [searchParams, setSearchParams] = React.useState({
    limit: ORDERS_FETCH_LIMIT,
    offset: 0,
    searchInput: '',
  })
  const [deliveriesOffset, setDeliveriesOffset] = React.useState(0)

  useEffect(() => {
    getAllCards()
  }, [getAllCards])

  const lastDeliveryElementRef = useCallback(
    (node) => {
      if (loading || !hasMoreDeliveries) return

      if (observer.current) {
        observer.current.disconnect() // Disconnect the old observer
      }

      observer.current = new IntersectionObserver(
        debounce((entries) => {
          if (
            entries[0].isIntersecting &&
            hasMoreDeliveries &&
            (activeTimeline === DeliveryFilterOption.ALL ||
              activeTimeline === DeliveryFilterOption.PAST)
          ) {
            observer.current.disconnect() // Disconnect before fetching
            setDeliveriesOffset(
              (prevOffset) => prevOffset + DELIVERIES_FETCH_LIMIT,
            )
            getFilteredDeliveries(
              dateFilter,
              '',
              deliveriesOffset + DELIVERIES_FETCH_LIMIT,
            )
          }
        }, 300),
        {
          rootMargin: '100px',
        },
      )

      if (node) observer.current.observe(node) // Attach observer to the node
    },
    [loading, hasMoreDeliveries, deliveriesOffset],
  )

  const handleModalClose = () => {
    setModal({
      isOpen: false,
      modalContent: '',
    })
    setSearchParams((prevParams) => ({
      ...prevParams,
      searchInput: searchInput,
      offset: 0, // Reset offset when performing a new search
    }))
    clearSearchedOrders()
  }

  async function searchOrders() {
    // Update searchParams
    setSearchParams((prevParams) => ({
      ...prevParams,
      searchInput: searchInput,
      offset: 0, // Reset offset when performing a new search
    }))

    // Trigger the search
    await getSearchedOrders(searchInput, searchParams.limit, 0)

    // Update the search term in the Redux store
    updateSearchTerm(searchInput)

    // Open the modal after the search is done
    setModal({
      isOpen: true,
      modalContent: 'searchedOrders', // You can adjust this content depending on what you want in the modal
    })
  }

  const onClearSearch = () => {
    setSearchInput('')
    updateSearchTerm('')
    // updateNarcoticsFilter(false)
    getFilteredDeliveries(dateFilter, '', 0)
    clearSearchedOrders()
  }

  // useEffect(() => {
  //   token && getFilteredDeliveries(dateFilter, '', isNarcotics)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isNarcotics])

  useEffect(() => {
    clearDeliveries()
    setDeliveriesOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeoutSearch = setTimeout(
      () => getFilteredDeliveries(dateFilter, '', 0),
      DEBOUNCE_DELAY,
    )
    setDeliveriesOffset(0)
    // clearDeliveries()
    return () => clearTimeout(timeoutSearch)
  }, [dateFilter])

  useEffect(() => {
    token && getStoreInfo(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, getDeliveries, getStoreInfo])

  return (
    <>
      <Container id="delivery-container">
        <NewDeliverySection onClick={newDelivery(history)} />
        <HeaderContainer>
          <Typography variant="h1">Delivery History</Typography>
        </HeaderContainer>
        <DeliveryFilter
          activeTimeline={activeTimeline}
          dateFilter={dateFilter}
          filterOptions={filterOptions}
          onClearSearch={onClearSearch}
          onChangeSearchInput={onChangeSearchInput}
          onSubmitSearch={searchOrders}
          searchInput={searchInput}
          // isNarcotics={isNarcotics}
          // updateNarcoticsFilter={updateNarcoticsFilter}
        />
        {showCurrentDeliveries && (
          <DeliveryTable
            deliveries={currentDeliveries}
            history={history}
            // lastElementRef={lastDeliveryElementRef}
          />
        )}
        <DeliveryTable
          deliveries={visibleDeliveries}
          history={history}
          lastElementRef={lastDeliveryElementRef}
        />
      </Container>
      <Modal isOpen={modal.isOpen}>
        {modal.modalContent === 'searchedOrders' && (
          <SearchedOrdersModal
            handleClose={handleModalClose}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )}
      </Modal>
      <Loading open={loading} />
    </>
  )
}

const mapStateToProps = ({
  app = {},
  user = {},
  deliveries = {},
  cards = {},
}) => ({
  deliveries: deliveries.history,
  user: user.userInfo,
  token: user.token,
  loading: app.loading,
  dateFilter: deliveries.dateFilter,
  searchTerm: deliveries.searchTerm,
  activeTimeline: deliveries.activeTimeline,
  isNarcotics: deliveries.isNarcotics,
  cardsFetched: cards.cardsFetched,
  hasMoreDeliveries: deliveries.hasMoreDeliveries,
})

const mapDispatchToProps = (dispatch) => ({
  getStoreInfo: (token) => dispatch(getStoreInfo(token)),
  getDeliveries: () => dispatch(getDeliveries()),
  getFilteredDeliveries: (dates, searchInput, offset) => {
    dispatch(getDeliveries({dates, searchInput, offset}))
  },
  setOrders: (orders) => dispatch(setOrders(orders)),
  getSearchedOrders: (searchInput) => {
    dispatch(searchOrders({searchInput, limit: 20, offset: 0}))
  },
  newDelivery: (history) => () => dispatch(addDelivery(history)),
  updateSearchTerm: (term) => dispatch(updateSearchTerm(term)),
  // updateNarcoticsFilter: (isNarcotics) =>
  //   dispatch(updateNarcoticsFilter(isNarcotics)),
  clearSearchedOrders: () => dispatch(clearSearchOrders()),
  getAllCards: () => dispatch(getAllCards()),
  clearDeliveries: () => dispatch(clearDeliveries()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
