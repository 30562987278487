import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import styled from 'styled-components'

import ProtectedRoute from './components/ProtectedRoute'
import ScrollToTop from './components/ScrollToTop'
import Login from './containers/Login'
import Dashboard from './containers/Dashboard'
import Admin from './containers/Admin'
import CreditCard from './containers/CreditCards'
import './App.css'
import VoiceInsights from './containers/VoiceInsights'
import {connect} from 'react-redux'
import {getAllCards} from './redux/actions'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-items: center;
`

function App({getAllCards}) {
  useEffect(() => {
    // getAllCards()
  }, [])
  return (
    <AppContainer>
      <ScrollToTop />
      <Switch>
        <ProtectedRoute path="/dashboard" component={Dashboard} />
        <ProtectedRoute path="/voice-insights" component={VoiceInsights} />
        <Route exact path="/" component={Login} />
        <ProtectedRoute path="/admin" component={Admin} />
        <ProtectedRoute path="/settings/payment" component={CreditCard} />
      </Switch>
    </AppContainer>
  )
}
const mapDispatchToProps = (dispatch) => ({
  getAllCards: () => dispatch(getAllCards()),
})

export default connect(null, mapDispatchToProps)(App)
