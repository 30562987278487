import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'

import {PrimaryButton} from '../Button'

// TODO: Potentially move this to the container folder because it's not meant as a reusable component
const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    minHeight: '13rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: `0px 4px 21px ${theme.card.shadow}`,
    borderRadius: '5px',
    padding: '28px 48px',
    overflow: 'visible',
  },
  content: {
    padding: 0,
    maxWidth: '57.5rem',
  },
  titleText: {
    marginBottom: '12px',
  },
  button: {
    width: '171px',
  },
}))

const NewDeliverySection = ({onClick}) => {
  const classes = useStyles()

  return (
    <Card className={classes.cardContainer}>
      <CardContent className={classes.content}>
        <Typography variant="h1" className={classes.titleText}>
          Start New Delivery
        </Typography>
        <Typography variant="body1">
          Select a delivery date then scan the barcode and customer information
          for each new order.
        </Typography>
      </CardContent>
      <PrimaryButton
        id="new-delivery-button"
        className={classes.button}
        onClick={onClick}>
        Start New Delivery
      </PrimaryButton>
    </Card>
  )
}

export default NewDeliverySection
