import {
  GET_DELIVERIES,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERIES_FAIL,
  GET_DELIVERY,
  GET_DELIVERY_SUCCESS,
  DELETE_DELIVERY,
  DELETE_DELIVERY_SUCCESS,
  UPDATE_DATE_FILTER,
  CLEAR_CURRENT_DELIVERY,
  ADD_DELIVERY,
  ADD_DELIVERY_SUCCESS,
  CANCEL_DELIVERY,
  CANCEL_DELIVERY_SUCCESS,
  UPDATE_DELIVERY,
  ADD_RETURNED_ORDERS,
  UPDATE_DELIVERY_DATA,
  GET_ONHOLD_ORDERS_SUCCESS,
  GET_ONHOLD_ORDERS_FAIL,
  GET_ONHOLD_ORDERS,
  ADD_ONHOLD_ORDERS,
  REMOVE_ONHOLD_ORDER,
  UPDATE_SEARCH_TERM,
  RESET_DELIVERY_FILTERS,
  UPDATE_DELIVERY_TIMELINE,
  UPDATE_NARCOTICS_FILTER,
  DUPLICATE_DELIVERY,
  DUPLICATE_DELIVERY_SUCCESS,
  DUPLICATE_DELIVERY_FAIL,
  NO_MORE_DELIVERIES,
  CLEAR_DELIVERIES_HISTORY,
} from './types'

import {getInitialStartAndEndDate} from '../../utils/dates'

const getDeliveries = ({dates, searchInput, offset}) => {
  const {sevenDaysInFuture, defaultStartDate} = getInitialStartAndEndDate()
  const datesObj = {
    startDate: defaultStartDate,
    endDate: dates ? new Date(dates.endDate) : sevenDaysInFuture,
  }
  const payload = !!searchInput
    ? {dates: datesObj, searchInput, offset}
    : {dates: datesObj, offset}
  return {
    type: GET_DELIVERIES,
    payload,
  }
}

const getDeliveriesSuccess = (deliveries) => {
  return {
    type: GET_DELIVERIES_SUCCESS,
    deliveries,
  }
}

const getDelivery = (deliveryId) => {
  return {type: GET_DELIVERY, payload: {deliveryId}}
}
const getDeliverySuccess = (delivery) => {
  return {type: GET_DELIVERY_SUCCESS, delivery}
}
const noMoreDeliveries = () => {
  return {type: NO_MORE_DELIVERIES}
}
const clearDeliveries = () => {
  console.log('CLEAR_DELIVERIES_HISTORY action dispatched')
  return {type: CLEAR_DELIVERIES_HISTORY}
}

const deleteDelivery = (history, deliveryId) => {
  return {type: DELETE_DELIVERY, payload: {history, deliveryId}}
}
const deleteDeliverySuccess = () => {
  return {type: DELETE_DELIVERY_SUCCESS}
}
const getDeliveriesFail = (error) => {
  return {type: GET_DELIVERIES_FAIL, error}
}

const clearCurrentDelivery = () => {
  return {type: CLEAR_CURRENT_DELIVERY}
}

const addDelivery = (history) => {
  return {
    type: ADD_DELIVERY,
    payload: {history},
  }
}
const addDeliverySuccess = (delivery) => {
  return {
    type: ADD_DELIVERY_SUCCESS,
    delivery,
  }
}

const cancelDelivery = (history, deliveryId) => {
  return {
    type: CANCEL_DELIVERY,
    payload: {history, deliveryId},
  }
}
const cancelDeliverySuccess = () => {
  return {type: CANCEL_DELIVERY_SUCCESS}
}

const updateDelivery = (payload) => {
  return {type: UPDATE_DELIVERY, payload}
}

const addReturnsToDelivery = (payload) => {
  return {type: ADD_RETURNED_ORDERS, payload}
}

const addOnHoldOrdersToDelivery = (payload) => {
  return {type: ADD_ONHOLD_ORDERS, payload}
}

const removeOnHoldOrder = (payload) => {
  return {type: REMOVE_ONHOLD_ORDER, payload}
}

const updateDeliveryData = (payload) => {
  return {type: UPDATE_DELIVERY_DATA, payload}
}

const getOnHoldOrders = (date) => {
  const ordersDate = date ? date : new Date()
  return {type: GET_ONHOLD_ORDERS, payload: {ordersDate}}
}

const getOnHoldOrdersSuccess = (returns) => {
  return {type: GET_ONHOLD_ORDERS_SUCCESS, returns}
}

const getOnHoldOrdersFail = (error) => {
  return {type: GET_ONHOLD_ORDERS_FAIL, error}
}

const updateSearchTerm = (term) => {
  return {type: UPDATE_SEARCH_TERM, term}
}

const updateDateFilter = (dates) => {
  const {sevenDaysInFuture, defaultStartDate} = getInitialStartAndEndDate()
  return {
    type: UPDATE_DATE_FILTER,
    payload: dates
      ? dates
      : {startDate: defaultStartDate, endDate: sevenDaysInFuture},
  }
}

const updateDeliveryTimeline = (filter) => {
  return {
    type: UPDATE_DELIVERY_TIMELINE,
    filter,
  }
}

const updateNarcoticsFilter = (isNarcotics) => {
  return {type: UPDATE_NARCOTICS_FILTER, isNarcotics}
}

const resetDeliveryFilters = () => {
  const {sevenDaysInFuture, defaultStartDate} = getInitialStartAndEndDate()
  return {
    type: RESET_DELIVERY_FILTERS,
    payload: {startDate: defaultStartDate, endDate: sevenDaysInFuture},
  }
}

const duplicateDelivery = (history, payload) => {
  return {type: DUPLICATE_DELIVERY, payload: {history, payload}}
}

const duplicateDeliverySuccess = (payload) => {
  return {type: DUPLICATE_DELIVERY_SUCCESS, payload}
}

const duplicateDeliveryFail = (error) => {
  return {type: DUPLICATE_DELIVERY_FAIL, error}
}

export {
  addOnHoldOrdersToDelivery,
  removeOnHoldOrder,
  getDeliveries,
  getDeliveriesSuccess,
  noMoreDeliveries,
  clearDeliveries,
  getDeliveriesFail,
  getDelivery,
  getDeliverySuccess,
  deleteDelivery,
  deleteDeliverySuccess,
  clearCurrentDelivery,
  addDelivery,
  addDeliverySuccess,
  cancelDelivery,
  cancelDeliverySuccess,
  updateDelivery,
  addReturnsToDelivery,
  updateDeliveryData,
  updateDateFilter,
  getOnHoldOrdersSuccess,
  getOnHoldOrdersFail,
  getOnHoldOrders,
  updateSearchTerm,
  resetDeliveryFilters,
  updateDeliveryTimeline,
  updateNarcoticsFilter,
  duplicateDelivery,
  duplicateDeliverySuccess,
  duplicateDeliveryFail,
}
