import React, {useEffect} from 'react'

import {connect} from 'react-redux'
import {getOrder} from '../../redux/actions'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {Dialog} from '@material-ui/core'
import LoadingModalOrange from '../LoadingModalOrange'

const useStyles = makeStyles((theme) => ({
  titleText: {
    marginBottom: '20px',
    textAlign: 'center',
    width: '100%',
  },
}))

const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`
const DialogContainer = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 450px;
  margin-bottom: 20px;
`

const DataTitle = styled.p`
  width: 100%;
  margin: 0rem;
  color: #8f8e9f;
`

const DataText = styled.p`
  width: 100%;
  margin: 0rem;
  color: #3a364f;
`

const DataContainer = styled.div`
  width: 50%;
`

const OrderDetailModal = ({
  handleClose = '',
  orderId,
  getOrder,
  order,
  isOpen,
  loading,
  showLoadingSpinner = true,
}) => {
  const classes = useStyles()
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        if (orderId) {
          await getOrder(orderId)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchOrder()
  }, [getOrder, orderId])

  const formatName = (customerName) => {
    const names = customerName.split(' ')
    if (names.length >= 2) {
      return `${names[names.length - 1]}, ${names.slice(0, -1).join(' ')}`
    } else {
      return names[0]
    }
  }

  const formatNote = (completionNote) => {
    return (
      <DataText>
        {completionNote.split('\n').map((line, index) => {
          if (line === '') return null
          return (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          )
        })}
      </DataText>
    )
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContainer>
        <DialogHeader disableTypography>
          <CloseIcon
            color="primary"
            onClick={handleClose}
            style={{cursor: 'pointer'}}
          />
        </DialogHeader>
        {/* Render your order here */}
        {!loading ? (
          <>
            <Typography variant="h1" className={classes.titleText}>
              Additional Details
            </Typography>
            <div
              style={{
                width: '100%',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <DataContainer>
                <DataTitle>Name</DataTitle>
                <DataText>
                  {order.customerName && formatName(order.customerName)}
                </DataText>
              </DataContainer>
              <DataContainer>
                <DataTitle>Driver Name</DataTitle>
                <DataText>{order.driverName}</DataText>
              </DataContainer>
              <DataContainer>
                <DataTitle>Task Completed</DataTitle>
                <DataText>{order.time}</DataText>
              </DataContainer>
            </div>
            <div
              style={{
                width: '100%',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <DataContainer style={{width: '100%'}}>
                <DataTitle>Note: </DataTitle>
                {order.completionNote && formatNote(order.completionNote)}
              </DataContainer>
            </div>

            {/* Display the images */}
            {order.signatureUpload &&
              order.signatureUpload.type === 'Buffer' &&
              order.signatureUpload.data && (
                <div style={{width: '100%', justifyContent: 'space-between'}}>
                  <DataTitle>Signature: </DataTitle>
                  <div style={{textAlign: 'center'}}>
                    <img
                      src={`data:image/jpeg;base64,${arrayBufferToBase64(
                        order.signatureUpload.data,
                      )}`}
                      alt="Order Signature Upload"
                      style={{width: '100px'}}
                    />
                  </div>
                </div>
              )}
            {order.photoUpload &&
              order.photoUpload.type === 'Buffer' &&
              order.photoUpload.data && (
                <div style={{width: '100%', justifyContent: 'space-between'}}>
                  <DataTitle>Photo: </DataTitle>
                  <div style={{textAlign: 'center'}}>
                    <img
                      src={`data:image/jpeg;base64,${arrayBufferToBase64(
                        order.photoUpload.data,
                      )}`}
                      alt="Order Confirmation Upload"
                      style={{width: '150px'}}
                    />
                  </div>
                </div>
              )}
          </>
        ) : (
          showLoadingSpinner && (
            <LoadingModalOrange
              style={{
                height: '100px',
                width: '100px',
              }}
              isStopped={false}
              isPaused={false}
            />
          )
        )}
      </DialogContainer>
    </Dialog>
  )
}

export const arrayBufferToBase64 = (buffer) => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return Buffer.from(binary, 'binary').toString('base64')
}

const mapStateToProps = ({orders, app: {loading}}) => {
  return {
    order: orders.order || {},
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getOrder: (orderId) => dispatch(getOrder({orderId})),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailModal)
