import React from 'react'
import styled from 'styled-components'

const Logo = styled.img`
  width: 48px;
  background-color: orange;
  padding: 16px 24px;
  border-radius: 0 0 6px 6px;
`
const StyledLogo = () => <Logo alt="script runner logo" src="/logo.svg" />

export default StyledLogo
