import React from 'react'
import Table from '../Table'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import {IconButton} from '@material-ui/core'

const DELIVERY_HEADERS = [
  {label: 'Date', width: 20},
  {label: 'Delivery Number', width: 12},
  {label: '# of Orders', width: 6},
  {label: 'Driver', width: 16},
  {label: 'Status', width: 18},
  {label: 'Returns', width: 6},
  {label: 'Duplicate', width: 4},
]

const DeliveryTable = ({deliveries = [], history, lastElementRef}) => {
  const handleDuplicateClick = (event, deliveryId) => {
    event.stopPropagation()
    history.push(`/dashboard/delivery/${deliveryId}/duplicate`)
  }

  // Preprocess deliveries to add the "Duplicate" column
  const modifiedDeliveries = deliveries.map((delivery, index) => {
    if (deliveries.length === index + 1) {
      return {
        ...delivery,
        duplicate: delivery.deliveryStatus === 'DELIVERY_COMPLETE' && (
          <IconButton
            onClick={(event) =>
              handleDuplicateClick(event, delivery.deliveryId)
            }
            size="small">
            <FileCopyIcon fontSize="small" />
          </IconButton>
        ),
      }
    } else {
      return {
        ...delivery,
        duplicate: delivery.deliveryStatus === 'DELIVERY_COMPLETE' && (
          <IconButton
            onClick={(event) =>
              handleDuplicateClick(event, delivery.deliveryId)
            }
            size="small">
            <FileCopyIcon fontSize="small" />
          </IconButton>
        ),
      }
    }
  })

  return (
    <>
      <Table
        marginTop="0"
        isDeliveryHistory={true}
        rows={modifiedDeliveries}
        headers={DELIVERY_HEADERS}
        properties={[
          'deliveryDate',
          'deliveryId',
          'totalOrders',
          'driverName',
          'deliveryStatus',
          'totalReturns',
          'duplicate',
        ]}
        dates={['deliveryDate']}
        keyProp="deliveryId"
        lastElementRef={lastElementRef}
        onClick={(deliveryId) => {
          history.push(`/dashboard/delivery/${deliveryId}`)
        }}
      />
    </>
  )
}

export default DeliveryTable
