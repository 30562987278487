import {put, takeLatest, select} from 'redux-saga/effects'
import {showError} from '../actions'
import {request} from '../../utils/api'
import {getToken} from '../utils'

import {
  SCAN_LABEL,
  SCAN_LABEL_SUCCESS,
  SCAN_LABEL_FAILURE,
  START_LOADING,
  STOP_LOADING,
} from '../actions/types'

import {API_LABELS_ENDPOINT} from '../../constants'

export function* scanLabel({payload: {image, history}}) {
  function updateRoute() {
    history.push(`${history.location.pathname}/add-new-prescription`)
  }

  if (!image) {
    yield put({type: SCAN_LABEL_SUCCESS})
    updateRoute()
    return
  }

  const token = yield select(getToken)
  const reqOpt = {
    method: 'POST',
    url: API_LABELS_ENDPOINT,
    token,
    body: image,
  }
  try {
    yield put({type: START_LOADING})

    const scanResult = yield request(reqOpt)

    yield put({type: STOP_LOADING})
    yield put({type: SCAN_LABEL_SUCCESS, payload: scanResult})
    updateRoute()
  } catch (error) {
    yield put({type: SCAN_LABEL_FAILURE})
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchLabels() {
  yield takeLatest(SCAN_LABEL, scanLabel)
}

export default watchLabels
