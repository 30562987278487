import get from 'lodash.get'
import {DELIVERY_STATUS_MAPPER, ORDER_STATUS_MAPPER} from './constants'

export function getStatusCopy(status) {
  return DELIVERY_STATUS_MAPPER[status]
}

export function getDeliveryStatusStep(status) {
  const currentStatus = DELIVERY_STATUS_MAPPER[status]
  return get(currentStatus, 'step', 1)
}

export function getOrderStatusStep(status) {
  const currentStatus = ORDER_STATUS_MAPPER[status]
  return get(currentStatus, 'step', 1)
}

export const barcodeParse = (barcode) => {
  const dollarPrice = barcode.substring(15, 20)
  const centPrice = barcode.substring(20, 22)
  return parseFloat(`${dollarPrice}.${centPrice}`)
}

export function isDeliveryIDSameAsPath(delivery) {
  const path = window.location.pathname
  const part = path.split('/').pop()
  return part === delivery.deliveryId
}

export function getPaymentStatus({orderStatus, paymentType}) {
  const FAILED_STATUS = 'PAYMENT_FAILURE'
  return orderStatus === FAILED_STATUS
    ? `${paymentType}: PAYMENT FAILED`
    : paymentType
}

export const formatName = (nameString) => {
  const names = nameString.split(' ').slice(0, -1)
  const lastNameArray = nameString.split(' ')
  const lastName = lastNameArray.slice(-1)[0]
  const formattedName = names.length >= 1 ? lastName + ', ' : lastName
  return formattedName + names.join(' ')
}

export function composeDeliveryFilters(payload) {
  const {searchInput, dates, offset} = payload
  const defaultPayload = {...dates, limit: 20}
  const containsSearchInput = !!searchInput
  return containsSearchInput
    ? {...defaultPayload, searchInput, offset}
    : {...defaultPayload, offset}
}
