import React, {useRef, useState, useEffect} from 'react'
import styled from 'styled-components'
import TextField from '../../components/Textfield'
import InputMask from 'react-input-mask'
import {Controller} from 'react-hook-form'
import get from 'lodash.get'
import theme from '../../theme'
import {getAllCards} from '../../redux/actions'
import {connect} from 'react-redux'
import Select from 'react-select'

import Error from '../../components/Error'

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  & > *:first-child {
    margin-right: 2rem;
  }
`

const ShippingForm = ({
  control,
  register,
  errors,
  setValue,
  setShippingFormFullName,
  setShippingFormPhoneNumber,
  setShippingFormAddress,
  setShippingFormDeliveryNote,
  setShippingFormCreditCard,
  cards,
  getAllCards,
  cardsFetched,
}) => {
  const [selectedCard, setSelectedCard] = useState()
  const [options, setOptions] = useState([])
  const fullNameInputRef = useRef()
  const phoneInputRef = useRef()
  const addressInputRef = useRef()
  const ccSelectRef = useRef()

  const selectStyle = {
    control: (styles, state) => ({
      ...styles,
      padding: '8px 12px',
      border: '1px solid #C4D5EE',
      color: theme.colors.label,
      borderRadius: '5px',
      backgroundColor: theme.input.primary.background,
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isDisabled
        ? theme.colors.error
        : state.isSelected
        ? 'white'
        : '#221E3A',
    }),
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedCard(selectedOption)
    setShippingFormCreditCard(selectedOption.cardId)
    setValue('creditCard', selectedOption.cardId)
  }

  useEffect(() => {
    if (!cardsFetched) getAllCards() // Fetch cards when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const d = new Date()
    const noneOption = [
      {
        name: 'NONE',
        id: 'NONE',
        cardId: 'NONE',
        isDisabled: false,
      },
    ]

    const allCards = []
    // const cardOptions =
    // cards &&
    // noneOption.concat(
    cards &&
      cards.forEach(
        (item) =>
          item.cards.forEach((card, index) => {
            allCards.push({
              name: `${item.given_name} ${item.family_name} ${
                item.nickname ? `(${item.nickname})` : ''
              } - ${card.card_brand} •••• ${card.last_4} Exp. ${String(
                card.exp_month,
              ).padStart(2, '0')}/${card.exp_year}`,
              id: index,
              cardId: item.id,
              isDisabled:
                card.exp_year < d.getFullYear() ||
                (card.exp_year === d.getFullYear() &&
                  card.exp_month < d.getMonth() + 1),
            })
          }),
        /* {

          name: `${item.given_name} ${item.family_name} ${
            item.nickname ? `(${item.nickname})` : ''
          } - ${item.cards[0].card_brand} •••• ${
            item.cards[0].last_4
          } Exp. ${String(item.cards[0].exp_month).padStart(2, '0')}/${
            item.cards[0].exp_year
          }`,
          id: index,
          cardId: item.id,
          isDisabled:
            item.cards[0].exp_year < d.getFullYear() ||
            (item.cards[0].exp_year === d.getFullYear() &&
              item.cards[0].exp_month < d.getMonth() + 1),
        } */
      )
    const cardOptions = noneOption.concat(allCards)
    // )
    setOptions(cardOptions)

    // Set initial selected card
    if (cardOptions?.length > 0 && !selectedCard) {
      const initialCard = cardOptions.find(
        (option) =>
          option.cardId === control.defaultValuesRef.current.creditCard,
      )
      setSelectedCard(initialCard)
      setValue('creditCard', initialCard?.cardId || '')
    }
  }, [cards])

  return (
    <>
      <Row>
        <Controller
          name="fullName"
          id="full-name"
          control={control}
          onFocus={() => {
            fullNameInputRef.current.focus()
          }}
          render={(props) => (
            <TextField
              label="Full Name"
              helperText={get(errors, 'fullName.message', '')}
              inputRef={fullNameInputRef}
              error={!!errors.fullName}
              FormHelperTextProps={{
                component: (props) => <Error name="fullName" {...props} />,
              }}
              onChange={(e) => {
                props.onChange(e)
                setShippingFormFullName(e.target.value)
              }}
              value={props.value}
            />
          )}
        />

        <Controller
          name="phoneNumber"
          control={control}
          rules={{required: 'Required'}}
          onFocus={() => {
            phoneInputRef.current.focus()
          }}
          render={(props) => {
            return (
              <InputMask
                maskChar=""
                mask="999-999-9999"
                onChange={(e) => {
                  props.onChange(e)
                  setShippingFormPhoneNumber(e.target.value)
                }}
                value={props.value}>
                {(inputProps) => (
                  <TextField
                    id="phone-number"
                    type="tel"
                    label="Phone"
                    name="phoneNumber"
                    inputRef={phoneInputRef}
                    error={!!errors.phoneNumber}
                    helperText={get(errors, 'phoneNumber.message', '')}
                    FormHelperTextProps={{
                      component: (props) => (
                        <Error name="phoneNumber" {...props} />
                      ),
                    }}
                  />
                )}
              </InputMask>
            )
          }}
        />
      </Row>
      <Controller
        name="shippingAddress"
        id="shipping-address"
        control={control}
        onFocus={() => {
          addressInputRef.current.focus()
        }}
        render={(props) => (
          <TextField
            type="text"
            label="Shipping Address"
            inputRef={addressInputRef}
            error={!!errors.shippingAddress}
            helperText={get(errors, 'shippingAddress.message', '')}
            FormHelperTextProps={{
              component: (props) => <Error name="shippingAddress" {...props} />,
            }}
            onChange={(e) => {
              props.onChange(e)
              setShippingFormAddress(e.target.value)
            }}
            value={props.value}
          />
        )}
      />

      <TextField
        type="text"
        label="Delivery Note (optional)"
        name="deliveryNote"
        id="delivery-note"
        inputRef={register}
        onChange={(e) => setShippingFormDeliveryNote(e.target.value)}
      />

      {options && (
        <div>
          <label
            style={{
              display: 'block',
              marginRight: '15px',
              marginBottom: '1rem',
              color: theme.colors.label,
            }}>
            Charge card on file
          </label>
          <Controller
            control={control}
            name="creditCard"
            id="credit-card"
            onFocus={() => {
              ccSelectRef.current.focus()
            }}
            render={(props) => {
              console.log('🚀 ~ props:', props)
              console.log('🚀 ~ options:', options)
              return (
                <Select
                  options={options}
                  styles={selectStyle}
                  inputRef={ccSelectRef}
                  onChange={(e) => {
                    props.onChange(e.cardId)
                    handleSelectChange(e)
                  }}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  value={options.find(
                    (card) =>
                      card.cardId === selectedCard?.cardId ||
                      card.cardId === props.value,
                  )}
                />
              )
            }}
          />
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({
  app: {loading},
  cards: {customers: cards, cardsFetched},
}) => {
  return {
    cards,
    cardsFetched,
    loading,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllCards: () => dispatch(getAllCards()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShippingForm)
