import {put, throttle} from 'redux-saga/effects'

import {GET_STORE, START_LOADING, STOP_LOADING} from '../actions/types'
import {getStoreInfoSuccess, showError} from '../actions'

import {API_STORE_ENDPOINT, API_THROTTLE_TIME_MS} from '../../constants'
import {request} from '../../utils/api'

export function* getStoreInfo({payload}) {
  const reqOpt = {
    method: 'GET',
    url: API_STORE_ENDPOINT,
    token: payload,
  }
  try {
    yield put({type: START_LOADING})
    const storeInfo = yield request(reqOpt)
    yield put(getStoreInfoSuccess(storeInfo))
    yield put({type: STOP_LOADING})
  } catch (error) {
    yield put({type: STOP_LOADING})
    yield put(showError(error))
  }
}

export function* watchUsers() {
  yield throttle(API_THROTTLE_TIME_MS, GET_STORE, getStoreInfo)
}

export default watchUsers
