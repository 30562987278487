import {
  SET_ORDERS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  ADD_ORDER,
  ADD_ORDER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  DELETE_ORDER,
  SEARCH_ORDERS,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_ORDERS_CLEAR,
  CLEAR_ORDERS,
  NO_MORE_ORDERS,
} from './types'

const setOrders = (payload) => {
  return {type: SET_ORDERS, payload}
}

const getOrder = (payload) => {
  return {type: GET_ORDER, payload}
}
const getOrderSuccess = (payload) => {
  return {type: GET_ORDER_SUCCESS, payload}
}

const addOrder = (payload) => {
  return {type: ADD_ORDER, payload}
}

const addOrderSuccess = (payload) => {
  return {type: ADD_ORDER_SUCCESS, payload}
}

const updateOrder = (payload) => {
  return {type: UPDATE_ORDER, payload}
}

const updateOrderSuccess = (payload) => {
  return {type: UPDATE_ORDER_SUCCESS, payload}
}

const deleteOrder = (payload) => {
  return {type: DELETE_ORDER, payload}
}

const searchOrders = (payload) => {
  return {type: SEARCH_ORDERS, payload}
}

const searchOrdersSuccess = (payload) => {
  return {type: SEARCH_ORDERS_SUCCESS, payload}
}

const clearSearchOrders = () => {
  return {type: SEARCH_ORDERS_CLEAR}
}

const clearOrders = () => {
  return {type: CLEAR_ORDERS}
}

const noMoreOrders = () => {
  return {type: NO_MORE_ORDERS}
}

export {
  setOrders,
  getOrder,
  getOrderSuccess,
  addOrder,
  addOrderSuccess,
  updateOrder,
  updateOrderSuccess,
  deleteOrder,
  searchOrders,
  searchOrdersSuccess,
  clearSearchOrders,
  clearOrders,
  noMoreOrders,
}
