import React, {useState, useEffect, useRef} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import BackButton from '../../components/BackButton'
import Typography from '@material-ui/core/Typography'
import TextField from '../../components/Textfield'
import Error from '../../components/Error'
import {Controller, useForm} from 'react-hook-form'
import { addCard, getAllCards } from '../../redux/actions'
import {connect} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import {object, string} from 'yup'
import {
  PrimaryButton,
  SecondaryButton,
} from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import CardList from './CardList'
import { useDispatch } from 'react-redux';
import CardInfo from './CardInfo'
import {Modal} from '../../components/Modal'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import { useHistory } from 'react-router-dom'
import AmexIcon from '../../assets/AmexIcon.svg'
import MasterCardIcon from '../../assets/MasterCardIcon.svg'
import VisaIcon from '../../assets/VisaIcon.svg'
import LoadingOrange from '../../components/LoadingOrange'
import {
  Container,
  TopContainer,
  BodyContainer,
  AddCardContainer,
  Row,
  CCContainer,
  ButtonGroup,
  HeaderTypography
} from './styles'

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    marginBottom: '1.2rem',
  },
  topContainer: {
    textAlign: 'center',
  },
  normalizeLabelSpace: {
    whiteSpace: 'normal'
  }
}))

const CustomSquareStyle = {
  '.input-container.is-focus': {
    borderWidth: '1px'
  },
}

const initializeCard = async (payments) => {
  const card = await payments.card({style: CustomSquareStyle});
  const cardContainer = document.querySelector('#card-container');
  
  // Clear existing content
  cardContainer.innerHTML = '';

  await card.attach('#card-container');
  return card;
};



const newCreditCardSchema = object().shape({
  nickname: string(),
  givenName: string().required('Required'),
  familyName: string().required('Required'),
})


const CreditCard = ({addCard, cards, userInfo, getAllCards, selectedCard = 'new-card', loading, locationId}) => {
  const [card, setCard] = useState(null);
  const [modalOpen, setModalOpen] = React.useState(false)
  const [squareLoading, setSquareLoading] = useState(false);
  const [storeApproval, setStoreApproval] = useState(false)
  const classes = useStyles();
  const nicknameInputRef = useRef()
  const givenNameInputRef = useRef()
  const familyNameInputRef = useRef()
  let payments = window.Square.payments(process.env.REACT_APP_SQUARE_APP_ID, locationId);
  const history = useHistory()
  
  const dispatch = useDispatch();
  const handleModalClose = () => {
    setModalOpen(false)
  }
  const {
    control,
    getValues,
    handleSubmit,
    trigger,
    formState: {errors},
  } = useForm({
    defaultValues: {
      nickname: '',
      givenName: '',
      familyName: '',
    },
    resolver: yupResolver(newCreditCardSchema),
  })

  const storeCard = async (token, verification_token) => {
    const {nickname, givenName, familyName} = getValues()
    const body = {
      customerBody: {
        nickname,
        given_name: givenName,
        family_name: familyName,

      },
      cardBody: {
        source_id: token,
        verification_token,
        idempotency_key: window.crypto.randomUUID(),
      }
    }
    //add the card
    addCard({body})
  }

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize()
    if(tokenResult.status === 'OK') {
      return tokenResult.token
    } else {
      throw new Error(
        `Tokenization errors: ${JSON.stringify(tokenResult.errors)}`
      )
    }
  }
  const verifyBuyer = async (payments, token) => {
    const {nickname, givenName, familyName} = getValues()
    const verificationDetails = {
      billingContact: {
        givenName,
        familyName,
        nickname
      },
      intent: 'STORE'
    }
    const verificationResults = await payments.verifyBuyer(token, verificationDetails)
    return verificationResults.token

  }
 
  const handleStoreCardSubmission = async (event, card, payments) => {
    event.preventDefault(); 
    try {
      setSquareLoading(true)
      await trigger();
      const token = await tokenize(card)
  
      let verificationToken = await verifyBuyer(payments, token)
      await storeCard(token, verificationToken)
    } catch(e) {
  
    } finally {
      setSquareLoading(false); // Reset loading state when the operation is finished
    }
  }

  useEffect(() => {
    const init = async () => {
      let card = await initializeCard(payments);
      setCard(card);
  
      // Cleanup function
      return () => {
        // Detach the Square iframe when the component unmounts
        if (card) {
          card.detach();
        }
      };
    };
  
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching cards...");
        await getAllCards();
        // Reinitialize Square iframe after fetching cards
        const initializedCard = await initializeCard(payments);
        setCard(initializedCard);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };
  
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
  },[loading])

  
  useEffect(() => {
    const init = async () => {
      // Reinitialize Square iframe when addCardClicked changes
      if (selectedCard === 'new-card') {
        console.log('Reinitializing Square iframe...');
        const payments = window.Square.payments(process.env.REACT_APP_SQUARE_APP_ID, locationId);
        let card = await initializeCard(payments);
        setCard(card);
      }
    };
  
    init();
  }, [selectedCard, locationId]);

  return (
    <Container id="credit-cards" key={selectedCard}>
      <TopContainer className={classes.topContainer}>
        <BackButton click={() => history.push('/dashboard')}/>
        <Typography variant="h1" className={classes.header}>
          Cards on file
        </Typography>
      </TopContainer>
      <BodyContainer>
        <CardList
          cards={cards}
        />
        {
          selectedCard === 'new-card' || cards.length === 0 ? 
            (<AddCardContainer>
              <form id='create-customer' style={{width: '100%'}} errors={errors} onSubmit={handleSubmit()}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', marginBottom: '24px'}}>
                  <HeaderTypography variant="h1" style={{marginBottom: '0px'}}>Add new card</HeaderTypography>
                  <div style={{display: 'flex', alignItems:'center'}}>
                    <img src={AmexIcon} alt="Amex Icon" style={{width: '100%', maxWidth: '30px', marginRight: '12px'}}/>
                    <img src={MasterCardIcon} alt="MasterCard Icon" style={{width: '100%', maxWidth: '30px', marginRight: '12px'}}/>
                    <img src={VisaIcon} alt="Visa Icon" style={{width: '100%', maxWidth: '30px'}}/>
                  </div>
                </div>

                <div style={{marginBottom: '24px'}}>
                  <Controller
                    name="nickname"
                    id="customer-nickname"
                    control={control}
                    onFocus={() => {
                      nicknameInputRef.current.focus()
                    }}
                    render={(props) => (
                      <TextField
                        label="Nickname (optional)"
                        inputRef={nicknameInputRef}
                        onChange={e => {
                          props.onChange(e);
                        }}
                        value={props.value}
                        />)
                      }
                      />
                </div>
                <Row>
                <Controller
                    name="givenName"
                    id="customer-given-name"
                    control={control}
                    onFocus={() => {
                      givenNameInputRef.current.focus()
                    }}
                    render={(props) => (
                      <TextField
                      label="First Name"
                      inputRef={givenNameInputRef}
                      onChange={e => {
                        props.onChange(e);
                      }}
                      error={errors && errors.givenName}
                      helperText={errors && errors.givenName && 'Required'}
                      value={props.value}
                      />)
                    }
                    />
                  <Controller
                  name="familyName"
                  id="customer-family-name"
                  control={control}
                  onFocus={() => {
                    familyNameInputRef.current.focus()
                  }}
                  render={(props) => (
                    <TextField
                      label="Last Name"
                      inputRef={familyNameInputRef}
                      onChange={e => {
                          props.onChange(e);
                      }}
                      error={errors && errors.familyName}
                      helperText={errors && errors.familyName && 'Required'}
                      value={props.value}
                    />)
                  }
                />
                </Row>
              <CCContainer>
                <div id="card-container" style={{minWidth: '500px', marginTop: '24px'}}></div>
                  <Checkbox
                    onChange={() => setStoreApproval(!storeApproval)}
                    defaultChecked={storeApproval}
                    label={`${userInfo['https://scriptrunner.com/store_number']} authorizes Scriptrunner Innovations Inc to charge the customers credit card on behalf of ${userInfo['https://scriptrunner.com/store_number']}, indicated in this authorization form according to the terms above. This payment authorization is valid until cancelled. The customer certifies that they are an authorized user of this credit card and that they will not dispute the payment with their credit card company so long as the transaction corresponds to the terms indicated in this form.`}
                    value={storeApproval}
                    containerStyles={{marginBottom: '24px', alignItems: 'flex-start'}}
                    labelStyles={classes.normalizeLabelSpace}
                    labelPlacement='end'
                  />
                  <ButtonGroup style={{display: 'flex', justifyContent: 'center'}}>
                    <SecondaryButton
                      id="cancel-button"
                      onClick={()=> {
                        history.push('/dashboard')
                      }}
                      style={{width: '170px'}}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={squareLoading || !storeApproval}
                      id="card-button" 
                      onClick={async () => {
                        
                        const givenNameState = await trigger('givenName')
                        const familyNameState = await trigger('familyName')
                        if(givenNameState && familyNameState)
                        setModalOpen(true)
                      }}
                      style={{width: '170px'}}
                      >
                      Proceed
                    </PrimaryButton>
                    {
                      loading ? 
                    <LoadingOrange />
                    : <></>
                    }

                  </ButtonGroup>
                  <Modal isOpen={modalOpen} handleClose={handleModalClose}>
                    <ConfirmationDialog
                      dialogTitle='Confirm New Card'
                      closeModal={handleModalClose}
                      handleContinueAction={
                        (e) => {
                          handleStoreCardSubmission(e, card, payments)
                          handleModalClose()
                      }}
                      handleCancelAction={handleModalClose}
                      description={'Once saved, you will not be able to make changes to this card.'}
                      cancelActionLabel="Continue Editing"
                      continueActionLabel="Confirm &amp; Save"
                      variant='primary'
                      />
                  </Modal>
              </CCContainer>
              </form>
            </AddCardContainer>
          ) 
          : (<CardInfo cards={cards} />)
        }
      </BodyContainer>

    </Container>
  )
}

const mapStateToProps = (state) => {
  return { 
    userInfo: state.user.userInfo,
    cards: state.cards.customers && state.cards.customers.sort((card1, card2) => {
      const a = (card1.given_name + card1.family_name).toLowerCase();
      const b = (card2.given_name + card2.family_name).toLowerCase();
      return a < b ? -1 : 1;
    }),
    selectedCard: state.cards.selectedCard,
    loading: state.app.loading,
    locationId: state.user.store.squareLocationId ? state.user.store.squareLocationId : null
  }
}
const mapDispatchToProps = (dispatch) => ({
  addCard: (payload) => dispatch(addCard(payload)),
  getAllCards: (payload) => dispatch(getAllCards(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)
