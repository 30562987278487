import {ca} from 'date-fns/locale'
import {
  ADD_CARD,
  ADD_CARD_SUCCESS,
  UPDATE_SELECTED_CARD,
  GET_ALL_CARDS,
  GET_ALL_CARDS_SUCCESS,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
} from '../actions/types'

function cardsDataReducer(state = {}, action = {}) {
  switch (action.type) {
    case ADD_CARD: {
      return {
        ...state,
        [action.payload]: action.payload,
      }
    }
    case ADD_CARD_SUCCESS: {
      const newCustomer = action.payload.customer
      const updatedCustomers = [...state.customers, newCustomer]
      const selectedCard = updatedCustomers[updatedCustomers.length - 1].id

      return {
        ...state,
        customers: updatedCustomers,
        selectedCard,
        cardsFetched: false,
      }
    }

    case GET_ALL_CARDS: {
      return {...state}
    }
    case GET_ALL_CARDS_SUCCESS: {
      return {...state, ...action.payload}
    }
    case UPDATE_SELECTED_CARD: {
      const clickedId = action.payload
      if (clickedId === 'new-card') {
        return {
          ...state,
          selectedCard: clickedId,
        }
      }
      // Find the card object in the "customers" array based on the clicked ID
      let selectedCard = state.customers.find((card) => card.id === clickedId)
      let selectedCardId = (selectedCard && selectedCard.id) || 'new-card'
      if (!selectedCard) {
        //deal with new customer case
        const newCustomer = action.payload
        selectedCardId = newCustomer.customer.id
      }
      return {
        ...state,
        selectedCard: selectedCardId,
      }
    }

    case DELETE_CARD: {
      return state
    }
    case DELETE_CARD_SUCCESS: {
      const {card_id} = action.payload

      // Filter out the customer that contains the card with the specified ID
      const updatedCustomers = state.customers.filter((customer) => {
        // Check if the customer has cards and if the card with card_id is present
        return !(
          customer.cards &&
          customer.cards.length > 0 &&
          customer.cards.some((card) => card.id === card_id)
        )
      })

      // Create the updated state with the new array of customers
      const updatedState = {
        ...state,
        customers: updatedCustomers,
        cardsFetched: false,
      }

      const updatedStateWithSelectedCard = {
        ...updatedState,
        selectedCard: 'new-card',
      }

      return updatedStateWithSelectedCard
    }

    default:
      return state
  }
}

export default cardsDataReducer
