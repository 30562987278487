import React from 'react'
import styled from 'styled-components'

import Slide from '@material-ui/core/Slide'

import ScanLabel from '../../containers/ScanLabel'
import Modal from './Modal'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StyledModal = styled(Modal)`
  & .MuiDialog-paper {
    background-color: transparent;
    box-shadow: none;
  }
  & .MuiBackdrop-root {
    visibility: hidden;
  }
`

const CameraModal = ({isCameraOpen, setIsCameraOpen}) => (
  <StyledModal
    style={{ zIndex: 1302, bottom: 'auto' }}
    TransitionComponent={Transition}
    isOpen={isCameraOpen}
    handleClose={() => setIsCameraOpen(false)}>
    <ScanLabel isCameraOpen={isCameraOpen} />
  </StyledModal>
)

export default CameraModal
