import React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {CreditCardIcon} from '../../components/Icons'
import {DangerButton} from '../../components/Button'
import {Modal} from '../../components/Modal'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import {deleteCard, updateSelectedCard} from '../../redux/actions'
import {connect} from 'react-redux'
import {useDispatch} from 'react-redux'

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  padding: 24px;
  width: 100%;
`
const HeaderTypography = styled(Typography)`
  margin-bottom: 24px;
`

const DangerText = styled.span`
  color: #e20f00;
`

const CardInfo = ({cards, selectedCard, deleteCard, updateSelectedCard}) => {
  const foundCard = cards.find((card) => card.id === selectedCard)
  const d = new Date()
  const [modalOpen, setModalOpen] = React.useState(false)
  const dispatch = useDispatch()

  if (!foundCard) {
    updateSelectedCard('new-card')
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <CardsContainer>
      {foundCard.cards?.map((card) => {
        const cardMonth = card.exp_month
        const cardYear = card.exp_year
        return (
          <CardContainer>
            <HeaderTypography variant="h1">
              {foundCard.given_name} {foundCard.family_name}{' '}
              {foundCard.nickname && foundCard.nickname !== ''
                ? `(${foundCard.nickname})`
                : ''}
            </HeaderTypography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                // flexDirection: 'column',
              }}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <CreditCardIcon />
                <span>{card.card_brand}</span>
                <span style={{marginLeft: '5px', color: '#706E84'}}>
                  {card.last_4}
                </span>
              </div>
              <div>
                Expiration{' '}
                {cardYear < d.getFullYear() ||
                (cardYear === d.getFullYear() &&
                  cardMonth < d.getMonth() + 1) ? (
                  <DangerText>{`${String(cardMonth).padStart(
                    2,
                    '0',
                  )}/${cardYear}`}</DangerText>
                ) : (
                  <span>{`${String(cardMonth).padStart(
                    2,
                    '0',
                  )}/${cardYear}`}</span>
                )}
              </div>
            </div>
            <Modal isOpen={modalOpen} handleClose={handleModalClose}>
              <ConfirmationDialog
                closeModal={handleModalClose}
                handleContinueAction={() => {
                  dispatch(deleteCard({card_id: foundCard.cards?.[0]?.id}))
                  handleModalClose()
                  updateSelectedCard('new-card')
                }}
                handleCancelAction={handleModalClose}
                description={'This card will be permanently removed.'}
                cancelActionLabel="cancel"
                continueActionLabel="Delete card"
                variant="danger"
              />
            </Modal>
          </CardContainer>
        )
      })}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <DangerButton
          style={{width: '171px', marginTop: '24px', textAlign: 'center'}}
          onClick={() => setModalOpen(true)}>
          Delete
        </DangerButton>
      </div>
    </CardsContainer>
  )
}

const mapStateToProps = ({cards}) => {
  return {
    selectedCard: cards.selectedCard,
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteCard: (payload) => dispatch(deleteCard(payload)),
  updateSelectedCard: (payload) => dispatch(updateSelectedCard(payload)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CardInfo)
