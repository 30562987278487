export const UPDATE_USER = 'UPDATE_USER'
export const ADD_DELIVERY = 'ADD_DELIVERY'
export const ADD_DELIVERY_SUCCESS = 'ADD_DELIVERY_SUCCESS'
export const ADD_DELIVERY_FAIL = 'ADD_DELIVERY_FAIL'
export const GET_DELIVERIES = 'GET_DELIVERIES'
export const GET_DELIVERIES_SUCCESS = 'GET_DELIVERIES_SUCCESS'
export const GET_DELIVERIES_FAIL = 'GET_DELIVERIES_FAIL'
export const GET_DELIVERY = 'GET_DELIVERY'
export const GET_DELIVERY_SUCCESS = 'GET_DELIVERY_SUCCESS'
export const GET_DELIVERY_FAILURE = 'GET_DELIVERY_FAILURE'
export const DELETE_DELIVERY = 'DELETE_DELIVERY'
export const DELETE_DELIVERY_SUCCESS = 'DELETE_DELIVERY_SUCCESS'
export const CLEAR_CURRENT_DELIVERY = 'CLEAR_CURRENT_DELIVERY'
export const CANCEL_DELIVERY = 'CANCEL_DELIVERY'
export const CANCEL_DELIVERY_SUCCESS = 'CANCEL_DELIVERY_SUCCESS'
export const UPDATE_DELIVERY = 'UPDATE_DELIVERY'
export const UPDATE_DATE_FILTER = 'UPDATE_DATE_FILTER'
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const UPDATE_DELIVERY_TIMELINE = 'UPDATE_DELIVERY_TIMELINE'
export const RESET_DELIVERY_FILTERS = 'RESET_DELIVERY_FILTERS'
export const DUPLICATE_DELIVERY = 'DUPLICATE_DELIVERY'
export const DUPLICATE_DELIVERY_SUCCESS = 'DUPLICATE_DELIVERY_SUCCESS'
export const DUPLICATE_DELIVERY_FAIL = 'DUPLICATE_DELIVERY_FAIL'
export const UPDATE_NARCOTICS_FILTER = 'UPDATE_NARCOTICS_FILTER'
export const NO_MORE_DELIVERIES = 'NO_MORE_DELIVERIES'
export const CLEAR_DELIVERIES_HISTORY = 'CLEAR_DELIVERIES_HISTORY'

export const SET_ORDERS = 'SET_ORDERS'

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const ADD_ORDER = 'ADD_ORDER'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_FAIL = 'ADD_ORDER_FAIL'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const DELETE_ORDER = 'DELETE_ORDER'
export const SEARCH_ORDERS = 'SEARCH_ORDERS'
export const SEARCH_ORDERS_SUCCESS = 'SEARCH_ORDERS_SUCCESS'
export const SEARCH_ORDERS_CLEAR = 'SEARCH_ORDERS_CLEAR'
export const CLEAR_ORDERS = 'CLEAR_ORDERS'
export const NO_MORE_ORDERS = 'NO_MORE_ORDERS'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const GET_STORE = 'GET_STORE'
export const GET_STORE_SUCCESS = 'GET_STORE_SUCCES'
export const SET_TOKEN_EXPIRY = 'SET_TOKEN_EXPIRY'

export const SCAN_LABEL = 'SCAN_LABEL'
export const SCAN_LABEL_SUCCESS = 'SCAN_LABEL_SUCCESS'
export const SCAN_LABEL_FAILURE = 'SCAN_LABEL_FAILURE'

export const DOWNLOAD_DELIVERY_REPORT = 'DOWNLOAD_DELIVERY_REPORT'
export const DOWNLOAD_STORE_REPORT = 'DOWNLOAD_STORE_REPORT'
export const DOWNLOAD_DRIVER_REPORT = 'DOWNLOAD_DRIVER_REPORT'

export const GET_PAYMENT = 'GET_PAYMENT'
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'
export const PAYMENT_METHOD_SUCCESS = 'PAYMENT_METHOD_SUCCESS'
export const CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD'
export const GET_LATEST_TRANSACTION = 'GET_LATEST_TRANSACTION'
export const GET_LATEST_TRANSACTION_SUCCESS = 'GET_LATEST_TRANSACTION_SUCCESS'
export const RESET_LATEST_TRANSACTION_SUCCESS =
  'RESET_LATEST_TRANSACTION_SUCCESS'

export const CREATE_PHARMACY = 'CREATE_PHARMACY'

export const SHOW_ERROR = 'SHOW_ERROR'
export const SILENCE_ERROR = 'SILENCE_ERROR'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'

export const TOGGLE_ADMIN_PAGE = 'TOGGLE_ADMIN_PAGE'

export const ADD_RETURNED_ORDERS = 'ADD_RETURNED_ORDERS'
export const GET_ONHOLD_ORDERS = 'GET_ONHOLD_ORDERS'
export const GET_ONHOLD_ORDERS_SUCCESS = 'GET_ONHOLD_ORDERS_SUCCESS'
export const GET_ONHOLD_ORDERS_FAIL = 'GET_ONHOLD_ORDERS_FAIL'
export const ADD_ONHOLD_ORDERS = 'ADD_ONHOLD_ORDERS'
export const REMOVE_ONHOLD_ORDER = 'REMOVE_ONHOLD_ORDER'

export const FETCH_DRIVERS = 'FETCH_DRIVERS'
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS'

export const FETCH_STORES = 'FETCH_STORES'
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS'

export const ADMIN_FETCH_STORE = 'ADMIN_FETCH_STORE'
export const EDIT_ADMIN_FETCH_STORE = 'EDIT_ADMIN_FETCH_STORE'

export const UPDATE_DELIVERY_DATA = 'UPDATE_DELIVERY_DATA'

export const UPDATE_PURCHASE_MODEL_FLAG = 'UPDATE_PURCHASE_MODEL_FLAG'

export const ADD_CARD = 'ADD_CARD'
export const UPDATE_SELECTED_CARD = 'UPDATE_SELECTED_CARD'
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS'
export const GET_ALL_CARDS = 'GET_ALL_CARDS'
export const GET_ALL_CARDS_SUCCESS = 'GET_ALL_CARDS_SUCCESS'
export const UPDATE_CARD = 'UPDATE_CARD'
export const GET_SINGLE_CARD = 'GET_SINGLE_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS'
export const SET_CARDS = 'SET_CARDS'

export const GET_VOICE_INSIGHTS = 'GET_VOICE_INSIGHTS'
export const GET_VOICE_INSIGHTS_SUCCESS = 'GET_VOICE_INSIGHTS_SUCCESS'
export const RESET_VOICE_INSIGHTS_DATA = 'RESET_VOICE_INSIGHTS_DATA'
